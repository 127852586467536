import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "regenerator-runtime/runtime";

import { store } from "state/store";
import App from "App";
import config from "config";
import { AppThemeProvider } from "providers/AppThemeProvider";

console.log("PACS Version: ", config.commitSHA);

Sentry.init({
  dsn: config.sentryDSN,
  environment: config.sentryEnv,
  integrations: [new BrowserTracing({ tracingOrigins: ["*"] }), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <AppThemeProvider>
      <App />
    </AppThemeProvider>
  </Provider>,
  document.getElementById("root")
);
